<template>
  <b-container fluid class="px-0 bg-white" style="height: 100vh;">
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <div class="d-flex align-items-center justify-content-between">
        <div v-if="step == 1" style="cursor: pointer" @click="backPage">
          <feather-icon size="24" class="text-dark font-weight-bolder" icon="XIcon" />
          <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Batal</span>
        </div>
        <div v-else style="cursor: pointer" @click.stop="stepForm('prev')">
          <feather-icon size="24" class="text-dark font-weight-bolder" icon="ChevronLeftIcon" />
          <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
        </div>
        <h1 class="text-dark h6 font-weight-bolder mb-0">
          Tambahkan Produk Masuk
        </h1>
        <b-button id="button--next" v-if="step == 1" class="px-3" :disabled="disabledStep1" @click="stepForm('next')">
          <!-- :disabled="Object.values(formSupplier).some(value => value === '')" -->
          Lanjut
        </b-button>
        <b-button id="button--next" v-else-if="step == 2" class="px-3" :disabled="disabled" @click="stepForm('next')">
          Lanjut
        </b-button>
        <b-button id="button--simpan" v-else-if="step == 3" class="px-3" :disabled="!paymentType || isLoading" @click="
          payment.payment_type == 'tempo'
            ? $route.query.warehouse === '1'
              ? submitWarehouse()
              : submitSupplier()
            : stepForm('next')
          ">
          <b-spinner small v-if="isLoading" />
          Simpan
        </b-button>
        <b-button id="button--simpan" v-else-if="step == 4" class="px-3" :disabled="isLoading" @click.prevent="
          $route.query.warehouse === '1'
            ? submitWarehouse()
            : submitSupplier()
          ">
          <b-spinner small v-if="isLoading" />
          Simpan
        </b-button>
      </div>
    </header>

    <div style="height: 130px"></div>

    <div v-if="step == 3" class="text-center">
      <h6 class="text-dark text-darken-4 fw-bold-800 mb-1">Total Harga</h6>
      <h5 class="size24 text-dark fw-bold-800 mb-1">
        {{ total | formatAmount }}
      </h5>
      <!-- <h6 class="text-dark text-darken-4 size12 fw-bold-800">
        Sudah di potong diskon Rp 230.000
      </h6> -->
    </div>

    <b-row class="mx-0 justify-content-center" :class="step >= 3 ? 'd-none' : step == 2 ? '' : ''">
      <b-col lg="5">
        <b-row>
          <b-col cols="12 mb-lg-4 mb-md-2">
            <div class="mb-2">
              <span class="text-dark text-darken-4 fw-bold-600 size14">Langkah {{ step }} dari 3 – Lengkapi Info</span>
            </div>
            <b-progress :value="progressValue" max="100" class="progress-bar-success" variant="success" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mx-0 mt-2 justify-content-center">
      <b-col :lg="step == 2 ? '12' : step == 4 ? '8' : '5'">
        <keep-alive>
          <component :is="comp" />
        </keep-alive>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BImg,
  BPagination,
  BFormSelect,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BProgress,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";

import FormAddIncomingProduct from "@/components/Stock/FormWizard/FormAddIncomingProduct.vue";
import FormPurchaseOrder from "@/components/Stock/FormWizard/FormPurchaseOrder.vue";

import FormAddIncomingProductSupplier from '@/components/Stock/FormWizard/FormAddIncomingProductSupplier.vue'
import FormPurchaseOrderSupplier from '@/components/Stock/FormWizard/FormPurchaseOrderSupplier.vue'

import FormPayment from "@/components/Stock/FormWizard/FormPayment.vue";
import FormListPayment from "@/components/Stock/FormWizard/FormListPayment.vue";

export default {
  components: {
    BContainer,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BImg,
    BPagination,
    BFormSelect,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BProgress,
    BForm,
    vSelect,
    FormAddIncomingProduct,
    FormPurchaseOrder,
    FormAddIncomingProductSupplier,
    FormPurchaseOrderSupplier,
    FormPayment,
    FormListPayment,
    BSpinner,
  },
  data() {
    return {
      comp: "FormAddIncomingProduct",
      step: 1,
      progressValue: 35,
      formPayload: {},
      paymentType: false,
      formSupplier: this.$store.state.stockWarehouse.formSupplier,
      formProducts: this.$store.state.stockWarehouse.formProducts,
      payment: {},
      isLoading: false,
      total: this.$store.state.stockWarehouse.totalProduct,
      disabled: true,
      disabledStep1: true,
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  watch: {
    step(value) {
      this.switchStep(value);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    },
    "$store.state.stockWarehouse.totalProduct": function (value) {
      this.total = value;
    },
    "$store.state.supplier.payment_type": function (value) {
      this.paymentType = value;
    },
    "$store.state.stockWarehouse.formSupplier": {
      handler(value) {
        if (this.$route.query.warehouse === "1") {
          this.formSupplier = {
            date: value.date === "Invalid date" ? "" : value.date,
            supplier_uuid: value?.supplier_uuid,
            // warehouse_uuid: value?.warehouse_uuid,
            delivery_numbers: value?.delivery_numbers,
            // po_so_id: value?.po_so_id,
            cash_total_payment: localStorage.getItem("cash_total_payment"),
          };
          this.disabledStep1 =
            Object.values(this.formSupplier).some((el) => el === "") ||
            this.formSupplier.delivery_numbers.some(
              (el) => el.delivery_number_note === ""
            );
          this.formSupplier.po_so_id = value.po_so_id;
        }
        if (this.$route.query.warehouse === "0") {
          this.formSupplier = {
            date: value.date === "Invalid date" ? "" : value.date,
            supplier_uuid: value?.supplier_uuid,
            shipping: value?.shipping || "",
            po_so_id: value?.po_so_id,
            delivery_numbers: value?.delivery_numbers,
            cash_total_payment: localStorage.getItem("cash_total_payment"),
          };
          this.disabledStep1 =
            Object.values(this.formSupplier).some((el) => el === "") ||
            this.formSupplier.delivery_numbers.some(
              (el) => el.delivery_number_note === ""
            );
        }
      },
      deep: true,
    },
    "$store.state.stockWarehouse.formProducts": {
      handler(value) {
        this.formProducts = value;
        this.disabledButtonFormPuchaseOrder();
      },
      deep: true,
    },
    "$store.state.stockWarehouse.payment": {
      handler(value) {
        this.payment = value;
      },
      deep: true,
    },
  },
  destroyed() {
    const itemsToRemove = [
      "cash_total_payment",
      "customer_supplier_uuid",
      "price",
      "payment_method",
      "payloadPayment",
      "terms_of_payment_day",
    ];
    itemsToRemove.forEach((item) => localStorage.removeItem(item));
    this.step = 1;
  },
  beforeDestroy() {
    this.step = 1;
  },
  mounted() {
    localStorage.removeItem("terms_of_payment_day");
    this.$router.replace({ query: { step: this.step, warehouse: this.$route.query.warehouse } });
    if (this.$route.name === "stock.gudang") {
      delete this.formSupplier.shipping;
      delete this.formSupplier.items;
    }
  },
  methods: {
    switchStep(steps) {
      const stepMap = {
        1: { comp: this.$route.query.warehouse === '1' ? 'FormAddIncomingProduct' : 'FormAddIncomingProductSupplier', progressValue: 35 },
        2: { comp: this.$route.query.warehouse === '1' ? 'FormPurchaseOrder' : 'FormPurchaseOrderSupplier', progressValue: 70 },
        3: { comp: "FormPayment", progressValue: 100 },
        default: { comp: "FormListPayment", progressValue: 100 },
      };
      const { comp, progressValue } = stepMap[steps] || stepMap.default;
      this.comp = comp;
      this.progressValue = progressValue;
    },
    disabledButtonFormPuchaseOrder() {
      const hasEmptyQty = this.formProducts.items.some(
        (e) => e.qty === "" || e.qty <= 0
      );
      const hasEmptyLabel = this.formProducts.items.some(
        (e) => !e.product_uuid.label
      );
      const hasEmptyPrice = this.formProducts.items.some(
        (e) => e.price === "" || e.price <= 0
      );
      const hasEmptyWarehouse = this.formProducts.items.some(
        (e) => e.warehouse_uuid === "" || e.warehouse_uuid <= 0
      );
      this.disabled =
        hasEmptyQty || hasEmptyLabel || hasEmptyPrice || hasEmptyWarehouse;
    },
    async submitWarehouse() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menambahkan stok gudang baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true;
          const { paymentDetail, payment_method } = this.$store.state.stockWarehouse;
          this.formPayload = {
            ...paymentDetail,
            ...this.formSupplier,
            ...this.formProducts.items,
            ...this.formProducts,
            ...this.payment,
            payment_method,
            cash_total_payment: localStorage.getItem("cash_total_payment"),
          };
          if (this.formPayload.payment_type === "tunai") {
            delete this.formPayload.tempo_due_date;
          } else if (this.formPayload.payment_type === "tempo") {
            delete this.formPayload.tunai_type;
            delete this.formPayload.payment_method;
            await this.deletePayloadTunai();
          }
          const form = this.preparePayload();
          try {
            await this.$store.dispatch("stockWarehouse/postData", {
              uuid: "",
              payload: form,
            });
            this.step = 1;
            this.isLoading = false;
            successNotification(
              this,
              "Success",
              "Stok gudang berhasil ditambahkan"
            );
            const name =
              this.$route.query.warehouse === "1"
                ? "stock.gudang"
                : "stock.supplier";
            this.$router.push({ name });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        }
      });
    },
    async submitSupplier() {
      this.$swal({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menambahkan stok gudang baru?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.isLoading = true;
          const { paymentDetail, payment_method } =
            this.$store.state.stockWarehouse;
          this.formPayload = {
            ...paymentDetail,
            ...this.formSupplier,
            ...this.formProducts.items,
            ...this.formProducts,
            ...this.payment,
            payment_method,
            cash_total_payment: localStorage.getItem("cash_total_payment"),
          };
          if (this.formPayload.payment_type === "tunai") {
            delete this.formPayload.tempo_due_date;
          } else if (this.formPayload.payment_type === "tempo") {
            delete this.formPayload.tunai_type;
            delete this.formPayload.payment_method;
            this.deletePayloadTunai();
          }
          const form = this.preparePayload();
          try {
            await this.$store.dispatch("stockWarehouse/postStockSupplier", {
              uuid: "",
              payload: form,
            });
            this.step = 1;
            this.isLoading = false;
            successNotification(
              this,
              "Success",
              "Stok pemasok berhasil ditambahkan"
            );
            const name =
              this.$route.query.warehouse === "1"
                ? "stock.gudang"
                : "stock.supplier";
            this.$router.push({ name });
          } catch (err) {
            this.isLoading = false;
            console.log(err);
          }
        }
      });
    },
    deletePayloadTunai() {
      const fieldsToDelete = [
        "bank_name",
        "bank_account_name",
        "bank_account_number",
        "bank_account_payment_uuid",
        "bank_transfer_attachment",
        "giro_account_name",
        "giro_account_number",
        "giro_bank",
        "giro_creation_date",
        "giro_effective_date",
        "card_name",
        "card_number",
        "card_exp_date",
      ];
      for (const field of fieldsToDelete) {
        delete this.formPayload[field];
      }
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (
            key !== "items" &&
            key !== "delivery_numbers" &&
            typeof this.formPayload[key] !== "object"
          ) {
            form.append(key, this.formPayload[key]);
          }
        }
      }
      this.formPayload.items.forEach((item, i) => {
        Object.keys(item).forEach((key) => {
          if (
            key !== "disc_percents" &&
            key !== "price" &&
            key !== "qty" &&
            key !== "product_uuid" &&
            key !== "discounts" &&
            key !== "additional_fees"
          ) {
            form.append(`items[${i}][${key}]`, item[key]);
          }
          if (key === "price" || key === "qty") {
            form.append(
              `items[${i}][${key}]`,
              parseFloat(this.preformatFloat(item[key]))
            );
          }
          if (key === "product_uuid") {
            form.append(`items[${i}][${key}]`, item[key].label || item[key]);
          }
        });
        item.discounts.forEach((discount, j) => {
          Object.keys(discount).forEach((key) => {
            form.append(`items[${i}][discounts][${j}][${key}]`, discount[key]);
          });
        });
        item.additional_fees.forEach((additional_fee, j) => {
          Object.keys(additional_fee).forEach((key) => {
            form.append(
              `items[${i}][additional_fees][${j}][${key}]`,
              additional_fee[key]
            );
          });
        });
      });
      if (this.formPayload.delivery_numbers) {
        this.formPayload.delivery_numbers.forEach((deliveryNumber, i) => {
          form.append(
            `delivery_numbers[${i}][delivery_number_note]`,
            deliveryNumber.delivery_number_note
          );
        });
      }
      return form;
    },
    preformatFloat(float) {
      if (!float) {
        return "";
      }
      const posC = float.indexOf(",");
      const posFS = float.indexOf(".");
      if (posC === -1) {
        return float.replace(/\./g, "");
      }
      if (posFS === -1) {
        return float.replace(/\,/g, ".");
      }
      return posC < posFS
        ? float.replace(/\,/g, "")
        : float.replace(/\./g, "").replace(",", ".");
    },
    stepForm(type) {
      this.step += type === "next" ? 1 : -1;
      this.$router.replace({ query: { step: this.step, warehouse: this.$route.query.warehouse } });
      this.switchStep(this.steps);
    },
    backPage() {
      this.$bvModal.hide("modal-add-product-in");
      this.$store.commit("stockWarehouse/setTotalProduct", this.total);
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
html,
#app {
  scroll-behavior: smooth;
}
@media screen and (min-height: 480px) and (max-height: 1024px) and (orientation: landscape) {
  .custom__pricelist--row {
    max-width: 12rem !important;
  }
  .custom__ppn--row {
    max-width: 5rem !important;
  }
  .custom__pricelist--col {
    max-width: 9.5rem !important;
    margin-right: 2rem !important;
    .custom__input {
      max-width: 9.5rem !important;
    }
  }
  .custom__ppn--col {
    max-width: 5rem !important;
  }
}
#modal-add-product-in {
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    .modal-content {
      height: 100vh;
      .modal-body {
        padding: 0;
        overflow-y: scroll;
        .progress-bar-success {
          background-color: #eff1f5;
          .progress-bar {
            background: linear-gradient(269.89deg,
                #6cdfd4 0%,
                #45b6ab 98.81%) !important;
            box-shadow: 0px 6px 8px rgba(44, 129, 120, 0.06);
            border-radius: 20px;
          }
        }
        label {
          font-size: 14px;
          color: $dark;
          font-weight: 600;
        }
        .vs__open-indicator {
          fill: rgba(60, 60, 60, 0.5);
        }
        .custom__input {
          height: 52px;
          background: #eff1f5;
          border: 1px solid #e4e5ec;
          border-radius: 8px;
        }
        .vs__dropdown-toggle {
          height: 52px;
          background-color: #eff1f5;
          border: 1px solid #e4e5ec;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>